import React from 'react';
import styles from './Contact.module.css';

const Contact = () => {
  return (
    <>
      <div className={styles.contact}>
        <div className={styles.contact_head}>
          <div className={styles.overlay}></div>
          <div className={styles.contact_head_text}>
            <h1>Contact Us</h1>
          </div>
        </div>

        <div className={styles.contact_main}>
          <div className={styles.form_container}>
            <form className={styles.contact_form}>
              <h2>Get in Touch</h2>
              <div className={styles.form_group}>
                <label htmlFor="firstName">First Name</label>
                <input type="text" id="firstName" name="firstName" required />
              </div>
              <div className={styles.form_group}>
                <label htmlFor="lastName">Last Name</label>
                <input type="text" id="lastName" name="lastName" required />
              </div>
              <div className={styles.form_group}>
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className={styles.form_group}>
                <label htmlFor="phone">Phone Number</label>
                <input type="tel" id="phone" name="phone" required />
              </div>
              <button type="submit" className={styles.submit_button}>Submit</button>
            </form>
          </div>
          <div className={styles.details_container}>
            <div className={styles.contact_details}>
              <div className={styles.details_box}>
                <i className="fa-solid fa-phone"></i>
                <p>+91 9325980847</p>
              </div>
              <div className={styles.details_box}>
                <i className="fa-solid fa-envelope"></i>
                <p>hghs_pune@yahoo.co.in</p>
              </div>
              <div className={styles.details_box}>
                <i className="fa-solid fa-school"></i>
                <p>44/4/1, N.I.B.M, SOCIETY, LINK ROAD, opposite HILLS & DALES, Undri, Pune, Maharashtra 411060, India</p>
              </div>
            </div>
            <div className={styles.map}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d484386.55511963455!2d73.914977!3d18.471427!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ea442786c97f%3A0x18a7e05064b8943e!2sHillgreen%20High%20School%20%26%20Junior%20College!5e0!3m2!1sen!2sus!4v1715943031198!5m2!1sen!2sus" style={{width:'100%', height:'100%', border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
