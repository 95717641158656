import React from 'react'
import styles from './SalientFeatures.module.css'


// Import Images and other things
import lab from '../../../Assets/About/Lab.webp'
import Library from '../../../Assets/About/Library.webp'
import visual from '../../../Assets/About/visual.webp'
import care from '../../../Assets/About/care.webp'
import gym from '../../../Assets/About/gym.webp'
import coach from '../../../Assets/About/coach.webp'
import playground from '../../../Assets/About/Ground.webp'
import dance from '../../../Assets/About/dance.webp'
import music from '../../../Assets/About/Music.webp'
import karate from '../../../Assets/About/Karate.webp'
import canteen from '../../../Assets/About/canteen.webp'
import tranport from '../../../Assets/About/transport.webp'
import cctv from '../../../Assets/About/cctv.webp'


const SalientFeatures = () => {
    return (
        <div className={styles.salientfeatures}>
            <div className={styles.salientfeatures_head}>
                <div className={styles.overlay_top}></div>
                <div className={styles.salientfeatures_head_text}>
                    <h1>Salient Features</h1>
                </div>
            </div>
            <div className={styles.salientfeatures_main}>
                <div className={styles.salientfeatures_main_head}>
                    <h1>Salient Features of The School</h1>
                </div>
                
                <div className={styles.salientfeatures_grid}>
                    {/* Box 1 */}
                    <div className={styles.salientfeatures_grid_box}>
                        <div className={styles.image_holder}>
                            <img className={styles.reletive} src={lab} alt='Hillgreen Lab' />
                            <div className={styles.overlay}></div>
                        </div>
                        <div className={styles.feature_text}>
                            <h1 >WELL EQUIPPED LABS</h1>
                            <p>Our well-equipped labs provide students with hands-on learning experiences, fostering exploration and discovery. From conducting experiments to exploring scientific principles. </p>
                        </div>
                    </div>

                    {/* Box 2 */}
                    <div className={styles.salientfeatures_grid_box}>
                        <div className={styles.image_holder}>
                            <img className={styles.reletive} src={Library} alt='Hillgreen Library' />
                            <div className={styles.overlay}></div>
                        </div>
                        <div className={styles.feature_text}>
                            <h1>RESOURCEFUL LIBRARY</h1>
                            <p>Our resourceful library serves as a hub for knowledge and inspiration, offering a vast collection of books, digital resources, and study spaces to support learning and exploration.</p>
                        </div>
                    </div>

                    {/* Box 3 */}
                    <div className={styles.salientfeatures_grid_box}>
                        <div className={styles.image_holder}>
                            <img className={styles.reletive} src={visual} alt='Hillgreen AUDIO-VISUAL ROOMS' />
                            <div className={styles.overlay}></div>
                        </div>
                        <div className={styles.feature_text}>
                            <h1>AUDIO-VISUAL ROOMS</h1>
                            <p>Our audio-visual rooms provide an immersive learning experience, combining visual aids with audio technology to enhance comprehension and engagement.</p>
                        </div>
                    </div>

                    {/*Box 4 */}
                    <div className={styles.salientfeatures_grid_box}>
                        <div className={styles.image_holder}>
                            <img className={styles.reletive} src={care} alt='Hillgreen CAREER COUNSELING SESSIONS' />
                            <div className={styles.overlay}></div>
                        </div>
                        <div className={styles.feature_text}>
                            <h1>CAREER COUNSELING SESSIONS</h1>
                            <p>Career counseling sessions offer personalized guidance and support to help individuals explore their interests, strengths, and career aspirations. </p>
                        </div>
                    </div>

                    {/* Box 5 */}
                    <div className={styles.salientfeatures_grid_box}>
                        <div className={styles.image_holder}>
                            <img className={styles.reletive} src={gym} alt='Hillgreen WELL EQUIPPED GYMNASIUM' />
                            <div className={styles.overlay}></div>
                        </div>
                        <div className={styles.feature_text}>
                            <h1>WELL EQUIPPED GYMNASIUM</h1>
                            <p>A well-equipped gymnasium provides individuals with a comprehensive fitness experience, featuring state-of-the-art exercise equipment and facilities. </p>
                        </div>
                    </div>

                    {/* Box 6 */}
                    <div className={styles.salientfeatures_grid_box}>
                        <div className={styles.image_holder}>
                            <img className={styles.reletive} src={coach} alt='Hillgreen EXCELLENT SPORTS COACHING' />
                            <div className={styles.overlay}></div>
                        </div>
                        <div className={styles.feature_text}>
                            <h1>EXCELLENT SPORTS COACHING</h1>
                            <p>Excellent sports coaching encompasses more than just skill development; it fosters a supportive and inspiring environment where athletes can thrive.</p>
                        </div>
                    </div>

                    {/* Box 7 */}
                    <div className={styles.salientfeatures_grid_box}>
                        <div className={styles.image_holder}>
                            <img className={styles.reletive} src={playground} alt='Hillgreen BIG PLAYGROUNDS' />
                            <div className={styles.overlay}></div>
                        </div>
                        <div className={styles.feature_text}>
                            <h1>BIG PLAYGROUNDS</h1>
                            <p>Big playgrounds offer expansive areas for children to engage in physical activity, fostering not only their physical health but also their social and cognitive development.</p>
                        </div>
                    </div>

                    {/* Box 8 */}
                    <div className={styles.salientfeatures_grid_box}>
                        <div className={styles.image_holder}>
                            <img className={styles.reletive} src={dance} alt='Hillgreen DANCE CLASSES' />
                            <div className={styles.overlay}></div>
                        </div>
                        <div className={styles.feature_text}>
                            <h1>DANCE CLASSES</h1>
                            <p>Dance classes provide a vibrant and dynamic environment for individuals to express themselves through movement and rhythm.</p>
                        </div>
                    </div>

                    {/* Box 9 */}
                    <div className={styles.salientfeatures_grid_box}>
                        <div className={styles.image_holder}>
                            <img className={styles.reletive} src={music} alt='Hillgreen MUSIC CLASSES' />
                            <div className={styles.overlay}></div>
                        </div>
                        <div className={styles.feature_text}>
                            <h1>MUSIC CLASSES</h1>
                            <p>Music classes offer a transformative journey into the world of melody, harmony, and rhythm. Students embark on a musical exploration, learning to play instruments, read sheet music, and understand music theory. </p>
                        </div>
                    </div>

                    {/* Box 10 */}
                    <div className={styles.salientfeatures_grid_box}>
                        <div className={styles.image_holder}>
                            <img className={styles.reletive} src={karate} alt='Hillgreen KARATE CLASSES' />
                            <div className={styles.overlay}></div>
                        </div>
                        <div className={styles.feature_text}>
                            <h1>KARATE CLASSES</h1>
                            <p>Karate classes instill discipline, self-confidence, and physical fitness in students through the ancient martial art of karate.  </p>
                        </div>
                    </div>

                    {/* Box 10 */}
                    <div className={styles.salientfeatures_grid_box}>
                        <div className={styles.image_holder}>
                            <img className={styles.reletive} src={canteen} alt='Hillgreen GOOD CANTEEN' />
                            <div className={styles.overlay}></div>
                        </div>
                        <div className={styles.feature_text}>
                            <h1>GOOD CANTEEN</h1>
                            <p>A good canteen offers more than just food; it provides a welcoming environment where students can socialize, relax, and refuel.</p>
                        </div>
                    </div>


                    {/* Box 11 */}
                    <div className={styles.salientfeatures_grid_box}>
                        <div className={styles.image_holder}>
                            <img className={styles.reletive} src={tranport} alt='Hillgreen TRANSPORTATION' />
                            <div className={styles.overlay}></div>
                        </div>
                        <div className={styles.feature_text}>
                            <h1>TRANSPORTATION</h1>
                            <p>A reliable transportation system is vital for ensuring that students have safe and convenient access to school.</p>
                        </div>
                    </div>

                    {/* Box 12 */}
                    <div className={styles.salientfeatures_grid_box}>
                        <div className={styles.image_holder}>
                            <img className={styles.reletive} src={cctv} alt='Hillgreen CCTV SURVEILLANCE' />
                            <div className={styles.overlay}></div>
                        </div>
                        <div className={styles.feature_text}>
                            <h1>CCTV SURVEILLANCE</h1>
                            <p>CCTV surveillance ensures round-the-clock monitoring of school premises, enhancing security and deterring misconduct. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SalientFeatures