import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Import your components for each route
import Gototop from './Components/Gototop/Gototop';
import ScrollToTop from './Components/ScrollToTop'
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home'
import SchoolHistory from './Components/About/SchoolHistory/SchoolHistory';
import Visionmission from './Components/About/VisionMission/VisionMission';
import SalientFeatures from './Components/About/SalientFeatures/SalientFeatures';
import OnlineAdmission from './Components/Admission/OnlineAdmission/OnlineAdmission';
import OfflineAdmission from './Components/Admission/OfflineAdmission/OfflineAdmission';
import Documents from './Components/Admission/Documents/Documents';
import Bankers from './Components/Admission/Bankers/Bankers';
import Rules from './Components/Learning/Rules/Rules';
import Curriculum from './Components/Learning/Curriculum/Curriculum';
import Assessments from './Components/Learning/Assessments/Assessments';
import Indoore from './Components/Activities/Indoor/Indoore';
import Academics from './Components/Learning/Academics/Academics';
import Outdoor from './Components/Activities/Outdoor/Outdoor';
import Clubs from './Components/Activities/Clubs/Clubs';
import StudemtsCorner from './Components/Learning/StudentsCorner/StudemtsCorner';
import Footer from './Components/Footer/Footer';
import Campus from './Components/About/Campus/Campus';
import Teachers from './Components/About/Teachers/Teachers';
import Teacherscorner from './Components/Activities/Teacherscorner/Teacherscorner';
import News from './Components/Whatshappening/News/News';
import Events from './Components/Whatshappening/Events/Events';
import Gallery from './Components/Gallery/Gallery';
import Testimonials from './Components/Testimonials/Testimonials';
import Contact from './Components/Contact/Contact';
import Holiday from './Components/Whatshappening/Holiday/Holiday';
import TestSchedule from './Components/Whatshappening/TestSchedule/TestSchedule';
import Calendar from './Components/Whatshappening/Calendar/SchoolCalendar';
import Faq from './Components/Whatshappening/Faq/Faq';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from './Assets/Navbar/Logo.png'
import './App.css'
import Career from './Components/Career/Career';
// import PopupModal from './Components/PopupModal/PopupModal';


const App = () => {
  return (
    <BrowserRouter basename="/"> {/* basename="/" */}

    <ScrollToTop/>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home/>} />   
        <Route path='/schoolhistory' element={<SchoolHistory/>} /> 
        <Route path='/visionmissin' element={<Visionmission/>} />
        <Route path='/teachers' element={<Teachers/>} />
        <Route path='/salientfeatures' element={<SalientFeatures/>}/>
        <Route path='/campus' element={<Campus/>} />
        <Route path='/onlineadmission' element={<OnlineAdmission/>} />
        <Route path='/offlineadmission' element={<OfflineAdmission/>} />
        <Route path='/documents' element={<Documents/>}/>
        <Route path='/bankers' element={<Bankers/>} />
        <Route path='/rules' element={<Rules/>} />
        <Route path='/curriculum' element={<Curriculum/>} />
        <Route path='/assessments' element={<Assessments/>} />
        <Route path='/indoor' element={<Indoore/>}/>
        <Route path='/academics' element={<Academics/>} />
        <Route path='/outdoor' element={<Outdoor/>} />
        <Route path='/clubs' element={<Clubs/>} />
        <Route path='/studentscorner' element={<StudemtsCorner/>}/> 
        <Route path='/teacherscorner' element={<Teacherscorner/>} />
        <Route path='/news' element={<News/>}/>
        <Route path='/events' element={<Events/>} />
        <Route path='/holiday' element={<Holiday/>} />
        <Route path='/test' element={<TestSchedule/>} />
        <Route path='/calendar' element={<Calendar/>}/>
        <Route path='/faq' element={<Faq/>} />
        <Route path='/gallery' element={<Gallery/>} />
        <Route path='/testimonials' element={<Testimonials/>} />
        <Route path='/career' element={<Career/>}/>
        <Route path='/contact' element={<Contact/>} />
      </Routes > 
      <Gototop/>
      <FloatingWhatsApp 
        avatar={logo}
        phoneNumber="+91 9325980847"
        accountName="Support"
        chatMessage="Hello there! 🤝 How can we help?"
        placeholder="Type a message.."
      />
      {/* <PopupModal/> */}
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
