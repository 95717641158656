import React from 'react'
import styles from './Documents.module.css'
const Documents = () => {
  return (
    <>
        <div className={styles.documents}>
            <div className={styles.documents_head}>
                <div className={styles.overlay}></div>
                <div className={styles.documents_head_text}>
                    <h1>Documents</h1>
                </div>
            </div>

            <div className={styles.documents_main}>
                <div className={styles.documents_list}>
                    <h1>Documents Required</h1>
                   

                    <ul className={styles.documents_list_li}>
                        <div className={styles.docs_list_flex}>
                            <div>
                            <div className={styles.loader}></div>
                            </div>
                              <li>TRANSFER CERTIFICATE</li>
                        </div>
                        <div className={styles.docs_list_flex}>
                            <div>
                            <div className={styles.loader}></div>
                            </div>
                            <li>BIRTH CERTIFICATE</li>
                        </div>
                        <div className={styles.docs_list_flex}>
                            <div>
                            <div className={styles.loader}></div>
                            </div>
                            <li>TWO RECENT COLOR PHOTOS OF STUDENT</li>
                        </div>
                        <div className={styles.docs_list_flex}>
                            <div>
                            <div className={styles.loader}></div>
                            </div>
                            <li>TWO RECENT COLOR PHOTOS OF STUDENT</li>
                        </div>
                        <div className={styles.docs_list_flex}>
                            <div>
                            <div className={styles.loader}></div>
                            </div>
                            <li>RESULT OF LAST YEAR CLASS ATTENDED</li>
                        </div>
                        <div className={styles.docs_list_flex}>
                            <div>
                            <div className={styles.loader}></div>
                            </div>
                            <li>ADHAR CARD OF STUDENT</li>
                        </div>
                   
                        
                        
                        
                       
                        
                    </ul>
                </div>
                 
            </div>
        </div>
    </>
  )
}

export default Documents