import React, { useState } from 'react'
import styles from './Rules.module.css'
import { Link } from 'react-router-dom'

const Rules = () => {

    const [generalDrop, setgeneralDrop] = useState(false);
    const [librarydrop, setlibrarydrop] = useState(false);
    const [recommendationdrop, setrecommendationdrop] = useState(false);

    const GeneralToggle = () => {
        setgeneralDrop(!generalDrop);
    }

    const libraryToggle = () => {
        setlibrarydrop(!librarydrop);
    }

    const recommendationToggle = () => {
        setrecommendationdrop(!recommendationdrop);

    }
    return (
        <>
            <div className={styles.rules}>
                <div className={styles.rules_head}>
                    <div className={styles.overlay}></div>
                    <div className={styles.rule_head_text}>
                        <h1>Rules</h1>
                    </div>
                </div>
                <div className={styles.rule_main}>
                    <div className={styles.rule_general_discipline}>
                        <div className={styles.general_discipline_inner}>
                            <div className={styles.rules_holder}>
                                <div className={styles.margin_for}>
                                <Link className={styles.links} onClick={GeneralToggle}>
                                    <h1>General Discipline  <i class="fa-solid fa-angle-down"></i></h1>
                                    <div className={`${styles.generalDrop} ${generalDrop ? styles.generaldropopen : ''}`}>
                                        <p>The parents and pupils should keep in mind the following principles of discipline, as laid down in the secondary schools code (1979)</p>
                                        <ul className={styles.rule_list}>
                                            <h1>1</h1>
                                            <li className={styles.rules_start}>Pupils should realize that they are responsible to the school authorities not only for their conduct in the school but also for their general behavior outside. Any reported or observed objectionable conduct out of the school on the part of pupils shall make them liable to disciplinary action.</li>
                                        </ul>
                                        <ul className={styles.rule_list}>
                                            <h1>2</h1>
                                            <li className={styles.rules_start}>Parents/ guardians are given to understand they cannot dictate to the school management and that the management has a right to say on what condition they will admit or retain pupils in the school.</li>
                                        </ul>
                                        <ul className={styles.rule_list}>
                                            <h1>3</h1>
                                            <li className={styles.rules_start}>
                                                regular attendance is one of the conditions under which a child is admitted. no leave of absence is granted except on previous written application from the parents or guardians and this will only be for serious reason, which should be mentioned. Absence without leave renders a child liable for punishment. Such absence before or after a holiday renders a child liable to be struck of the rolls.</li>
                                        </ul>
                                        <ul className={styles.rule_list}>
                                            <h1>4</h1>
                                            <li className={styles.rules_start}>
                                                Those children who absent themselves from the school without the knowledge of their parents/ guardians will be liable to severe disciplinary action or even dismissal. Should such absence be during the time of unit test/ exam. He/ she will be given zero (0) marks for the respective unit test/ exam.</li>
                                        </ul>
                                        <ul className={styles.rule_list}>
                                            <h1>5</h1>
                                            <li className={styles.rules_start}>

                                                Pupils must be present on the closing and the opening day of the school before and after any vacation.</li>
                                        </ul>
                                        <ul className={styles.rule_list}>
                                            <h1>6</h1>
                                            <li className={styles.rules_start}>

                                                Absence for more that 2 days is permitted only for medical reasons for which medical certificate should be provided.</li>
                                        </ul>
                                        <ul className={styles.rule_list}>
                                            <h1>7</h1>
                                            <li className={styles.rules_start}>

                                                Newly admitted children must be in their respective classes from the first working day of the school or their names will be struck off and other children will be admitted in the resultant vacancies thereof.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>8</h1>
                                            <li className={styles.rules_start}>
                                                Non availability of railway reservation or any such excuses will not be accepted as a reason for non attendance by any student on the opening day of the school after any vacation.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>9</h1>
                                            <li className={styles.rules_start}>
                                                regular attendance and study habits, prompt obedience, courtesy in speech and conduct, cleanliness of dress and person, a decent haircut of the boys are must.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>10</h1>
                                            <li className={styles.rules_start}>

                                                Hence, irregular attendance, habitual idleness, unsatisfactory application to studies, disobedience, objectionable moral influence and unsatisfactory conduct (like whistling, shouting etc) in and out of school, justify the dismissal of a student from the school.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>11</h1>
                                            <li className={styles.rules_start}>
                                                No student, who has been absent from the school, shall be admitted to the class without the principal’s permission with signature in the calendar.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>12</h1>
                                            <li className={styles.rules_start}>
                                                A pupil, who fails consecutively twice in the same standard is liable to be asked t leave the school</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>13</h1>
                                            <li className={styles.rules_start}>
                                                Children of school going age are not expected to take part on political activities.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>14</h1>
                                            <li className={styles.rules_start}>

                                                Any pupil who is persistently insubordinate or is repeatedly or willfully mischievous or is guilty of malpractices in connection with examinations or has committed an act of serious indiscipline and/ or misbehavior, or who, in the opinion of the head of the school, has an unwholesome influence on his fellow pupils, may be expelled permanently or removed from the school for a specified period by the head of the school.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>15</h1>
                                            <li className={styles.rules_start}>
                                                All the children must look after their own belongings. Coats, raincoats, caps jerseys, umbrellas, Tiffin boxes and all the books must be marked with the names and classes of the owners. It is not advisable for a child to have money or any other valuables eth him/ her. The school will not be responsible for the things. Which are lost by the students? Girls should not wear ornaments of gold and silver.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>16</h1>
                                            <li className={styles.rules_start}>
                                                Books, periodicals, magazines, comics or pictures of any kind, which are not approved by the school, will be confiscated if found in possession of the students.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>17</h1>
                                            <li className={styles.rules_start}>
                                                Any students breaking the above rules will be liable to pay a fine and / or face any other disciplinary action, as a decided upon by the principal.</li>
                                        </ul>
                                    </div>
                                </Link>
                                </div>

                                <div className={styles.margin_for}>
                                <Link className={styles.links} onClick={libraryToggle}>
                                    <h1>Library Rules  <i class="fa-solid fa-angle-down"></i></h1>
                                    <div className={`${styles.librarydrop} ${librarydrop ? styles.librarydropopen : ''}`}>
                                        <ul className={styles.rule_list}>
                                            <h1>1</h1>
                                            <li className={styles.rules_start}>Pupils should realize that they are responsible to the school authorities not only for their conduct in the school but also for their general behavior outside. Any reported or observed objectionable conduct out of the school on the part of pupils shall make them liable to disciplinary action.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>2</h1>
                                            <li className={styles.rules_start}>Any book borrowed by a student, if not required by another student, may be reissued to him/ her for another week.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>3</h1>
                                            <li className={styles.rules_start}>Certain books, magazines etc. Are available only for reference in the library.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>4</h1>
                                            <li className={styles.rules_start}>A student is allowed to keep a library book with him/ her only for a mixumum period of one week from the date of issue of the book to him/ her. Failure to return the book to the library on the due date will make the defaulting student liable to pay a late fee @ Rs. 1/ per day for the excess period in which the book had been kept with him/ her. Over and above permitted one week.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>5</h1>
                                            <li className={styles.rules_start}>Library books must not be exchanged or interchanged by the students between them. Each student is fully responsible for the book that he / she borrows from the library.</li>
                                        </ul>


                                        <ul className={styles.rule_list}>
                                            <h1>6</h1>
                                            <li className={styles.rules_start}>If a book is lost or destroyed by a student, the full market value of the bok of the lost/ destroyed book will be recovered from him/ her.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>7</h1>
                                            <li className={styles.rules_start}>
                                                Books should be treated with care both in and out of the library. No writing inkblots, torn pages, jam marks and the likes are allowed.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>8</h1>
                                            <li className={styles.rules_start}>
                                                All the books should be returned at least a fortnight before the commencement of the annual examination</li>
                                        </ul>
                                        <ul className={styles.rule_list}>
                                            <h1>9</h1>
                                            <li className={styles.rules_start}>

                                                Any student breaking the above rules will be liable to pay a fine and / or face any other disciplinary action, as decided upon by the principal.</li>
                                        </ul>
                                    </div>
                                </Link>
                                </div>


                                <div className={styles.margin_for}>
                                <Link className={styles.links} onClick={recommendationToggle}>
                                    <h1>Recommendation to The Parents  <i class="fa-solid fa-angle-down"></i></h1>
                                    <div className={`${styles.recommendationdrop} ${recommendationdrop ? styles.recommendationdropopen : ''}`}>
                                        <p>The school expects co-operation from the parents/ guardians to ensure:</p>
                                        <ul className={styles.rule_list}>
                                            <h1>1</h1>
                                            <li className={styles.rules_start}>That the children come to the school regularly, punctually and neatly dressed.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>2</h1>
                                            <li className={styles.rules_start}>That the children bring to the school their books and note books, which are properly maintained.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>3</h1>
                                            <li className={styles.rules_start}>That the children bring their school calendar regularly to the school</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>4</h1>
                                            <li className={styles.rules_start}>That the children do their homework regularly.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>5</h1>
                                            <li className={styles.rules_start}>That the homework is signed every day by the parents/ guardians.</li>
                                        </ul>


                                        <ul className={styles.rule_list}>
                                            <h1>6</h1>
                                            <li className={styles.rules_start}>That the occasional report made by the teacher in the school calendar is signed and returned by the parents/ guardians.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>7</h1>
                                            <li className={styles.rules_start}>

                                                That they comply, as early as possible, when requested to meet the teacher or principal any matter concerning their children, who are the students of out schools.</li>
                                        </ul>

                                        <ul className={styles.rule_list}>
                                            <h1>8</h1>
                                            <li className={styles.rules_start}>
                                                Non availability of railway reservation or any such excuses will not be accepted as a reason for non attendance by any student on the opening day of the school after any vacation.</li>
                                        </ul>
                                        <ul className={styles.rule_list}>
                                            <h1>9</h1>
                                            <li className={styles.rules_start}>

                                                That they do not ring up the school telephone nos. Except in urgent cases. No requests from the parents/ guardians for calling the students and / or staff to the phone shall be entertained under any circumstances, whatsoever.</li>
                                        </ul>
                                    </div>
                                </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Rules