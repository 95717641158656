import React from 'react';
import Slider from './Slider/SliderHero';
import Absolute from './Absolute/Absolute';
import Intro from './Intro/Intro';
import Faculty from './Faculty/Faculty';
import Testimonials from './Testimonials/Testimonials';
import Why from './Why/Why';
import Principal from './Principal/Principal';
import styles from './Home.module.css';

const Home = () => {
  return (
    <>
      <div className={styles.marquee}>
        <p>
          Admission Open! Enroll now and secure your child's future.{' '}
          Connect with Us +91 9325980847 / <a href="mailto:hghs_pune@yahoo.co.in" aria-label="Email">hghs_pune@yahoo.co.in</a>
        </p>
      </div>
      <Slider />
      <Absolute />
      <Intro />
      <Faculty />
      <Principal />
      <Testimonials />
      <Why />
    </>
  );
};

export default Home;
