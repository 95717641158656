import React from 'react';
import styles from './VisionMission.module.css';
import square from '../../../Assets/list.png';

const VisionMission = () => {
    return (
        <div className={styles.visionmission}>
            <div className={styles.visionmission_head}>
                <div className={styles.overlay}></div>
                <div className={styles.visionmission_head_text}>
                    <h1>Vision & Mission</h1>
                </div>
            </div>

            {/* Vision and Mission Sections */}
            <div className={styles.vision_flexbox}>
                <div className={styles.vision_box}>
                    <div>
                        <h2>OUR VISION</h2>
                        <div className={styles.main_content}>
                            <p>We the Principal, Teachers & Staff of Hillgreen High School and Junior College</p>
                            <p>Pledge to offer parents the best education and development for their children by nurturing the values Strength, Wisdom, and Character, which will lead them to success and satisfaction in life.</p>
                        </div>
                    </div>
                </div>

                <div className={styles.vision_box}>
                    <div>
                        <h2>OUR MISSION</h2>
                        <p>As the Potter moulds the clay, we will:</p>
                        <ul className={styles.points}>
                            <li className={styles.list_styles}>
                                <img src={square} alt="Bullet point" />
                                <span>Ensure the overall development of each student</span>
                            </li>
                            <li className={styles.list_styles}>
                                <img src={square} alt="Bullet point" />
                                <span>Adopt progressive techniques in education.</span>
                            </li>
                            <li className={styles.list_styles}>
                                <img src={square} alt="Bullet point" />
                                <span>Guide students in achieving outstanding results.</span>
                            </li>
                            <li className={styles.list_styles}>
                                <img src={square} alt="Bullet point" />
                                <span>Develop skills, endurance, and a positive attitude to face challenges.</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VisionMission;
