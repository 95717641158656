import React from 'react';
import styles from './Teachers.module.css';
import teachersData from './TeachersData';
import ScrollAnimation from 'react-animate-on-scroll';

const Teachers = () => {
    // Organize teachersData into categories
    const coordinators = teachersData.find(category => category.category === 'COORDINATORS');
    const hods = teachersData.find(category => category.category === "HOD'S");
    const teachingStaff = teachersData.find(category => category.category === 'TEACHING STAFF');

    return (
        <div className={styles.teachers}>
            <div className={styles.teachers_head}>
                <div className={styles.overlay}></div>
                <div className={styles.teachers_head_text}>
                    <ScrollAnimation animateIn='fadeIn'>
                        <h1>Teachers</h1>
                    </ScrollAnimation>
                </div>
            </div>

            <main className={styles.teachers_main}>
                <ScrollAnimation animateIn='fadeInUp'>
                    <header className={styles.teacher_main_head}>
                        <h2>Our Pillars</h2>
                        <p>
                            All subjects are taught by skilled, qualified, experienced and dedicated teachers - all experts in imparting education to children. These teachers prepare the children not only to excel in their academics, but also in the real world outside. Tolerant, understanding and open-minded, they leverage modern and innovative teaching methods to extract outstanding results. What's more, the sprinkling of teachers from NGO backgrounds ensures that each child has a sensitive and compassionate sounding board to round off the learning experience. Sharing the conviction of the school, that learning should be a fun-filled and joyous experience, these teachers are also friends and guides to the children. They are the exhilarating wind beneath the fledgling wings of the children that helps them to soar high.
                        </p>
                    </header>
                </ScrollAnimation>

                {/* Render Coordinators */}
                {/* <h3>COORDINATORS</h3> */}
                <section className={styles.teachersList}>
                   
                    {coordinators && coordinators.teachers.map((teacher) => (
                        <ScrollAnimation animateIn='fadeInUp' key={teacher.id}>
                            <article className={styles.teacherCard}>
                                <img src={teacher.image} alt={`${teacher.name}'s profile`} className={styles.teacherImage} />
                                <h3>{teacher.name}</h3>
                                <p>{teacher.designation}</p>
                            </article>
                        </ScrollAnimation>
                    ))}
                </section>

                {/* Render HODs */}
                {/* <h3>HOD'S</h3> */}
                <section className={styles.teachersList}>
                   
                    {hods && hods.teachers.map((teacher) => (
                        <ScrollAnimation animateIn='fadeInUp' key={teacher.id}>
                            <article className={styles.teacherCard}>
                                <img src={teacher.image} alt={`${teacher.name}'s profile`} className={styles.teacherImage} />
                                <h3>{teacher.name}</h3>
                                <p>{teacher.designation}</p>
                            </article>
                        </ScrollAnimation>
                    ))}
                </section>

                {/* Render Teaching Staff */}
                <section className={styles.teachersList}>
                    {/* <h3>TEACHING STAFF</h3> */}
                    {teachingStaff && teachingStaff.teachers.map((teacher) => (
                        <ScrollAnimation animateIn='fadeInUp' key={teacher.id}>
                            <article className={styles.teacherCard}>
                                <img src={teacher.image} alt={`${teacher.name}'s profile`} className={styles.teacherImage} />
                                <h3>{teacher.name}</h3>
                                <p>{teacher.designation}</p>
                            </article>
                        </ScrollAnimation>
                    ))}
                </section>
            </main>
        </div>
    );
}

export default Teachers;
