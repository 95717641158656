import React, { useRef, useEffect } from 'react';
import styles from './SchoolHistory.module.css';
import history from '../../../Assets/About/History/Vid.mp4';

const SchoolHistory = () => {
  const videoRef = useRef(null);
  const isPlaying = useRef(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const handlePlayVideo = (entries) => {
      entries.forEach(entry => {
        const video = videoRef.current;
        if (!video) return;

        if (entry.isIntersecting && !isPlaying.current) {
          video.play().catch((error) => {
            console.error('Error attempting to play video:', error);
          });
          isPlaying.current = true;
        } else if (!entry.isIntersecting && isPlaying.current) {
          video.pause();
          isPlaying.current = false;
        }
      });
    };

    const observer = new IntersectionObserver(handlePlayVideo, options);

    const currentVideo = videoRef.current; // Copy ref to a local variable

    if (currentVideo) {
      observer.observe(currentVideo);
    }

    return () => {
      if (currentVideo) {
        observer.unobserve(currentVideo);
      }
    };
  }, []);

  return (
    <div className={styles.schoolhistory}>
      <div className={styles.schoolhistory_head}>
        <div className={styles.overlay}></div>
        <div className={styles.schoolhistory_head_text}>
          <h2>Know Us Better</h2>
          <h1>About Us</h1>
        </div>
      </div>
      <div className={styles.schoolhistory_maincontent}>
        <div className={styles.schoolhistory_section}>
          <div className={styles.schoolhistory_section_title}>
            <h2>Hillgreen's History</h2>
          </div>

          <div className={styles.scrollenvelope}>
            <video ref={videoRef} muted title="Hillgreen High School History Video">
              <source src={history} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolHistory;
